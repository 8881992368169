import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '@/shared/utils';

/**
 * get all comments
 * @param {String} xid
 * @param {Number} replyTo
 *
 * @return {Array}
 */
export const getItemComments = async (xid, replyTo = 0) => {
	try {
		const {
			data: { GetItemCommentList }
		} = await apollo.query({
			query: gql`
                query ($xid: String!, $replyTo: ID) {
                    GetItemCommentList(Xid: $xid, ReplyTo: $replyTo)
                    ${imageComment}
                }
            `,
			variables: {
				xid, // task
				replyTo: parseInt(replyTo, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetItemCommentList;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * add comment
 * @param {String} xid
 * @param {String} message
 *
 * @return {Object}
 */
export const addComment = async (xid, message, replyTo) => {
	replyTo = parseInt(replyTo, 10);
	try {
		const mutation = gql`
            mutation ($xid: String!, $message: String!, $replyTo: ID!) {
                AddTaskComment(
                    Xid: $xid
                    Message: $message
                    ReplyTo: $replyTo
                )
                ${imageComment}
            }
        `;
		const {
			data: { AddTaskComment }
		} = await apollo.mutate({
			mutation,
			variables: {
				xid,
				message,
				replyTo
			}
		});

		return AddTaskComment;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * update comment
 * @param {Number} itemCommentId
 * @param {String} message
 *
 * @return {Object}
 */
export const updateComment = async (itemCommentId, message) => {
	try {
		const mutation = gql`
            mutation ($itemCommentId: ID!, $updTaskComment: ImageCommentInput!) {
                UpdateItemComment(
                    ItemCommentId: $itemCommentId
                    UpdTaskComment: $updTaskComment
                )
                ${imageComment}
            }
        `;
		const {
			data: { UpdateItemComment }
		} = await apollo.mutate({
			mutation,
			variables: {
				itemCommentId,
				updTaskComment: {
					message,
					isModified: true
				}
			}
		});

		return UpdateItemComment;
	} catch (e) {
		console.error({ e });
	}
};

export const addUpdateLike = async (itemCommentId, itemCommentLikeStatus) => {
	try {
		const mutation = gql`
			mutation ($itemCommentId: ID!, $itemCommentLikeStatus: Boolean!) {
				AddItemCommentLike(ItemCommentId: $itemCommentId, ItemCommentLikeStatus: $itemCommentLikeStatus)
			}
		`;

		const {
			data: { AddItemCommentLike }
		} = await apollo.mutate({
			mutation,
			variables: {
				itemCommentId: parseInt(itemCommentId, 10),
				itemCommentLikeStatus
			}
		});

		return AddItemCommentLike;
	} catch (e) {
		console.error({ e });
	}
};

export const getItemCommentLikeStatus = async (itemCommentId) => {
	try {
		const {
			data: { GetItemCommentLikeStatus }
		} = await apollo.query({
			query: gql`
				query ($itemCommentId: ID!) {
					GetItemCommentLikeStatus(ItemCommentLikeId: $itemCommentId)
				}
			`,
			variables: {
				itemCommentId: parseInt(itemCommentId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetItemCommentLikeStatus;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {Object} comment - {xid: String, message: String, like: Boolean}
 */
export const delItemComment = async (commentId) => {
	if (isNil(commentId)) return;
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($ItemCommentId: ID!) {
					DelItemComment(ItemCommentId: $ItemCommentId)
				}
			`,
			variables: {
				ItemCommentId: parseInt(commentId)
			}
		});
		return data.DelItemComment;
	} catch (e) {
		console.log({ e });
	}
};

const imageComment = `{
    id
    createdAt
    imageChildId
    userId
    message
    commentType
    commentPicture
    insertedOn
    xPos
    yPos
    replyTo
    doLike
    xid
    name
    firstName
    picture
    isModified
}`;
